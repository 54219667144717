import React from "react"
import { FormattedMessage } from "react-intl"

const TalentBerlinPartnersWithMostJobsHeader: React.FC = () => {
  return (
    <h2 id="talent-berlin-partners" className="talent-berlin-subtitle">
      <FormattedMessage
        id="talentberlin.partners.title"
        defaultMessage="Our partners"
      />
    </h2>
  )
}

export default TalentBerlinPartnersWithMostJobsHeader
